import React, {useCallback, useState} from 'react';
import ImageViewer from "react-simple-image-viewer";
import {Card, Row, Col} from "react-bootstrap";

function Plans(props) {
    const [state, setState] = useState({activePlan: false, activeIndex: 0})
    const openImageViewer = useCallback((home, index) => {
        setState(prev => {
            return {...prev, activePlan: home, activeIndex: index}
        })
    }, []);
    const closeImageViewer = () => {
        setState(prev => {
            return {...prev, activePlan: false, activeIndex: 0}
        })
    };
   
    return (
    <>
        {
            Object.keys(props.homes).map(home => {

                return (
                    <Card className="bg-body-color my-5 border-top" key={`home-card-${props.homes[home].title}`}>
                        <Card.Body>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <h3>{props.homes[home].title}</h3>
                                    <h5>{props.homes[home].desc}</h5>
                                    <h6>{props.homes[home].size}</h6>
                                </Col>
                                <Col xs={12} lg={8}>
                                    <div className="row d-flex justify-content-center justify-content-lg-start">
                                        {props.homes[home].images.map((img, i) => {
                                            return (
                                                <div className="col-4" key={`col-img-${i}`}>
                                                    <img src={img} alt={props.homes[home].title + " Plan"} className="w-100"
                                                         onClick={() => openImageViewer(home, i)}/>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </Col>
                            </Row>


                        </Card.Body>


                    </Card>
                )
            })

        }


        {state.activePlan && (
            <ImageViewer
                src={props.homes[state.activePlan].images}
                currentIndex={state.activeIndex}
                onClose={closeImageViewer}
                disableScroll={true}
                backgroundStyle={{
                    backgroundColor: "rgba(255,255,255,1)"
                }}
                closeOnClickOutside={true}
            />
        )}

    </>)



}

export default Plans;