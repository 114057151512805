import React, {Component} from 'react';
import {ArrowContainer, Popover} from "react-tiny-popover";
import {Card} from "react-bootstrap";

class TinyPopOver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopoverOpen: this.props.open
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.mykey !== this.props.mykey){
            this.setPopoverOpen(true)
        }
    }

    setPopoverOpen = (bool) => {
        this.setState({isPopoverOpen: bool})
    }
    togglePopoverOpen = () => {
        this.setState(prevState => {
            prevState.isPopoverOpen = !prevState.isPopoverOpen
            return prevState
        })
    }

    render() {
        return (
            <Popover
                isOpen={this.state.isPopoverOpen}
                positions={['bottom']} // if you'd like, you can limit the positions
                padding={4} // adjust padding here!
                reposition={true} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                onClickOutside={() => this.setPopoverOpen(false)} // handle click events outside of the popover/target here!
                content={({position, childRect, popoverRect}) => {
                    return (
                        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            arrowColor={"#54565b"}
                            arrowSize={10}
                            arrowStyle={{opacity: 1}}
                            className='popover-arrow-container'
                            arrowClassName='popover-arrow'
                        >
                            <Card
                                className={'popover-card bg-primary text-white'}
                            >
                                <Card.Body className='p-2 small'>
                                    {this.props.children}
                                </Card.Body>
                            </Card>
                        </ArrowContainer>
                    )
                }}
            >
                <button onClick={this.togglePopoverOpen} type="button" className='lh-1 d-inline m-0 p-0 ms-1 btn btn-sm bg-transparent text-primary'
                        >{this.props.button}</button>
            </Popover>

        );
    }
}

export default TinyPopOver;