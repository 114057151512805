import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import CalculatorAccordion from "./CalculatorAccordion";
import "../../assets/sass/external.scss"
import Header from "./Header";
import Blurb from "./Blurb";
import CookieConsentBanner from "../CookieConsentBanner";
import FirstTimeBuyers from "./FirstTimeBuyers";

import Plans from "../Plans";
import Siteplan from "../Siteplan";
import Benefits from "../Benefits";
import RegisterInterest from "../RegisterInterest";
function Index(props) {
    const homes = {
        a: {
            title: "TYPE A",
            desc: "3 Bedroom + Study Mid Terrace",
            size: "1,455 Sq.Ft. |  135  Sq. M",
            images: [
                "/assets/images/plans/type-a-ground.png",
                "/assets/images/plans/type-a-first.png",
                "/assets/images/plans/type-a-second.png",
            ],
            color: "#008265"
        },
        b: {
            title: "TYPE B",
            desc: "3 Bedroom End Terrace",
            size: "1,190 Sq.Ft. |  110 Sq. M",
            images: [
                "/assets/images/plans/type-b-ground.png",
                "/assets/images/plans/type-b-first.png",
            ],
            color:"#e7aad0"
        },
        b1: {
            title: "TYPE B1",
            desc: "3 Bedroom End Terrace",
            size: "1,190 Sq.Ft. |  110 Sq. M",
            images: [
                "/assets/images/plans/type-b1-ground.png",
                "/assets/images/plans/type-b1-first.png",
            ],
            color: "#fbd33e"
        },
        c2: {
            title: "TYPE C2",
            desc: "3 Bedroom Mid Terrace",
            size: "1,341 Sq.Ft. |  125 Sq. M",
            images: [
                "/assets/images/plans/type-c2-ground.png",
                "/assets/images/plans/type-c2-first.png",
            ],
            color: '#121d4e'
        },
        d1: {
            title: "TYPE D1",
            desc: "4 Bedroom End Terrace",
            size: "1,678 Sq.Ft. |  156 Sq. M",
            images: [
                "/assets/images/plans/type-d1-ground.png",
                "/assets/images/plans/type-d1-first.png",
                "/assets/images/plans/type-d1-second.png",
            ],
            color: '#f6971b'
        }
    }

    return (
        <>
            <div className="min-vh-100 w-100 iframe-container">
                <Header/>

                <Container className="px-xs-0-only">
                    <Row className="py-4 gx-0 gx-sm-5">
                        <Col className="blurb order-2 order-lg-1" xs={12} lg={7}>
                            <Card className="mt-3 bg-body-color">
                                <Card.Body className="p-md-5">
                                    <Blurb/>
                                </Card.Body>
                                <div className="bg-img1 text-white"
                                     style={{backgroundImage: 'url("/assets/images/canal-gate.jpg")'}}>
                                    {/*<div*/}
                                    {/*    className="d-flex flex-column flex-sm-row justify-content-around align-content-stretch  position-relative text-start text-sm-center">*/}
                                    {/*    <div className="p-2 py-3 small" style={{backgroundColor: 'rgba(0,59,74,0.75)'}}>*/}
                                    {/*        Natural stone on certain elevations and boundaries*/}
                                    {/*    </div>*/}
                                    {/*    <div className="p-2 py-3 small "*/}
                                    {/*         style={{backgroundColor: 'rgba(29,99,116,0.75)'}}>*/}
                                    {/*        Architecturally designed unique homes*/}
                                    {/*    </div>*/}
                                    {/*    <div className="p-2 py-3 small "*/}
                                    {/*         style={{backgroundColor: 'rgba(87,144,158,0.75)'}}>*/}
                                    {/*        Elevated ceilings make for rooms that feel open*/}
                                    {/*    </div>*/}
                                    {/*    <div className="p-2 py-3 small"*/}
                                    {/*         style={{backgroundColor: 'rgba(127,168,174,0.75)'}}>*/}
                                    {/*        Professionally designed landscaping throughout*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                                <Card.Body className="p-md-5">
                                    <h3>For First Time Buyers</h3>
                                    <FirstTimeBuyers/>
                                </Card.Body>

                            </Card>
                            <Row className="my-3 my-md-5 mx-5 px-0 px-md-5 align-items-center">
                                <Col>
                                    <img className=" w-100" src="/assets/images/ber-a.png" alt="BER A"
                                         loading="lazy"/>

                                </Col>
                                <Col>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.09 18.71">
                                        <title>ber-a2</title>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="type">
                                                <g>
                                                    <polygon points="0 0 0 18.71 49.26 18.71 53.09 9.06 49.19 0 0 0"
                                                             fill="#fff"></polygon>
                                                    <polygon
                                                        points="47.77 2.14 29.79 2.14 32.88 9.12 29.79 16.58 47.77 16.58 50.76 9.06 47.77 2.14"
                                                        fill="#00a650"></polygon>
                                                    <path
                                                        d="M36.66,10.23h2l-1-3.16h0Zm-1,3H33.9l2.85-8h1.93l2.81,8H39.68l-.51-1.65h-3Z"
                                                        fill="#fff"></path>
                                                    <path
                                                        d="M42.1,8.15c0-2.57,1.87-2.79,2.58-2.79a2.46,2.46,0,0,1,2.71,2.49,3.05,3.05,0,0,1-1.64,2.57c-1,.73-1.6,1.08-1.78,1.45H47.4v1.38H42a3.52,3.52,0,0,1,1.83-3.13c1.43-1,2-1.44,2-2.33a1.07,1.07,0,0,0-1.1-1.12c-1.09,0-1.12.9-1.13,1.48Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                    <path d="M27.47,2.14l3.08,7-3.08,7.46m0,0H2.14V2.14H27.47"></path>
                                                    <path
                                                        d="M6.92,11.94H9c.81,0,1.27-.35,1.27-1.16s-.6-1-1.2-1H6.92Zm0-3.47H8.87c.66,0,1.18-.26,1.18-.89s-.46-.86-1.22-.86H6.92ZM5.32,5.35H9.2c2,0,2.46,1.22,2.46,2a1.59,1.59,0,0,1-1,1.63A1.86,1.86,0,0,1,11.91,11a2.28,2.28,0,0,1-.69,1.64c-.45.44-.89.72-2.52.72H5.32Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                    <polygon
                                                        points="18.81 6.76 14.57 6.76 14.57 8.44 18.47 8.44 18.47 9.85 14.57 9.85 14.57 11.91 19 11.91 19 13.32 12.94 13.32 12.94 5.35 18.81 5.35 18.81 6.76"
                                                        fill="#fff"></polygon>
                                                    <path
                                                        d="M23.54,8.86c.87,0,1.29-.3,1.29-1.09,0-.43-.18-1-1.16-1H21.59V8.86ZM20,5.35h4A2.24,2.24,0,0,1,26.5,7.6a1.9,1.9,0,0,1-1.23,1.9c.66.29,1,.53,1,2,0,1.17,0,1.46.36,1.59v.21H24.79a5.35,5.35,0,0,1-.18-1.61c0-1.09-.07-1.51-1.3-1.51H21.59v3.12H20Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </Col>
                            </Row>


                        </Col>
                        <Col className="order-1 order-lg-2 " xs={12} lg={5}>
                            <div className=" shadow mt-lg--100 mb-5">
                                <RegisterInterest/>

                            </div>
                            <CalculatorAccordion/>

                        </Col>
                    </Row>




                </Container>

                <Benefits/>

                <div className="bg-body-color py-lg-5">
                    <Container className="px-xs-0-only" style={{overflowX: 'hidden'}}>
                        <h3 className="text-center my-5">Floor Plans</h3>
                        <Plans homes={homes}/>
                    </Container>
                </div>
                <div className="bg-white py-5">
                    <Container className="px-xs-0-only">
                        <h3 className="text-center my-5">Site Plan</h3>

                        <Siteplan homes={homes}/>
                    </Container>
                </div>
                <div className="bg-info text-info py-3 py-lg-4">
                    <Container className="px-xs-0-only">
                        <Row className="gx-0 gx-sm-3 justify-content-center">
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center h-100 py-4 d-flex flex-column"
                                         style={{backgroundColor: '#d3d3d3'}}>
                                        <h3> SELLING AGENTS</h3>
                                        <div className="mt-auto">
                                            <img src="/assets/images/coonan.png" width={"120"}
                                                 loading="lazy" alt="Coonan's Logo"/>
                                        </div>

                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center py-4 h-100 d-flex flex-column"
                                         style={{backgroundColor: '#d3d3d3'}}>
                                        <h3> DEVELOPER</h3>
                                        <div className="mt-auto mx-auto" style={{backgroundColor:'black',padding: '1rem 1rem',margin:'1rem 1rem',width:'180px'}}>

                                            <img src="/assets/images/rycroft.svg" loading="lazy" width="100%"
                                                 alt="Vesta Logo"/>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center py-4 h-100 d-flex flex-column"
                                         style={{backgroundColor: '#d3d3d3'}}>
                                        <h3> MORTGAGE PARTNER</h3>
                                        <div className="mt-auto mb-2">
                                            <img src="/assets/images/sussd-logo.svg" loading="lazy"
                                                 alt="Sussd Logo"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>
            <CookieConsentBanner/>
        </>
);
}

export default Index;