import React, { useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import Preloader from "./Preloader";
import SussdLogo from "./SussdLogo";
import base64 from "base-64";
import axios from "axios";

function RegisterInterest(props) {
    const [state,setState] = useState({
        errors:{},
        status:null,
        needsMortgage:false,
    })

    const handleSubmit = (e)=>{
        e.preventDefault()
        let jsElements = e.target.elements
        let elements = Array.from(jsElements).filter(i=>!!i?.name)
        let errors = getErrors(elements)
        if (!Object.keys(errors).length) {
            // submit form
            let data = {}
            elements.forEach(el=>{
                data[el.name] = el.value
            })
            data.source = base64.encode(window.location.href)
            data.type = 'interest'
            const endpoint = 'https://itdhvdcdl4ozbvsic34gs6kxzu0okfdm.lambda-url.eu-west-1.on.aws/'
            axios.post(endpoint, JSON.stringify(data)).then(response => {
                setState(p=> {
                    return {
                        ...p,
                        status: 'submitted'
                    }
                })
            }).catch(error => {
                console.log(error)
            })
        }
        setState(p=>{
          return {
              ...p,
              errors: errors,
              status: Object.keys(errors).length > 0 ? null:'submitting',
              needsMortgage: jsElements.financial.value === "Seeking a Mortgage"
          }
        })
    }

    const handleChange = (e) =>{
        setState(p=>{
            let x = {...p}
            x.errors[e.target.name] = false
            return x
        })

    }

    const getErrors = (elements) =>{
        let errors = {}
        elements.forEach(i=>{
            if (["","Choose ..."].includes(i.value)){
                errors[i.name] = 'Value Missing'
            }
            if (i.name==='email'){

                if (!(/(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+))/.test(i.value))) {

                 errors[i.name] = 'Not a valid email'
                }
            }
        })

        return errors
    }
    return (
        <Card className="bg-light">
            <Form onSubmit={handleSubmit}>
                <Card.Header className="bg-info">
                    <h3 className="bold my-3 text-center text-white">Register Your Interest</h3>
                </Card.Header>
                <Card.Body className="position-relative">
                    <Row className="bold align-items-start">
                        <Col xs={6}>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control className={!!state.errors?.firstName ? 'is-invalid':''} type="text" placeholder="First Name" name="firstName" onChange={handleChange} />
                                <div className="invalid-feedback font-normal">
                                        {state.errors.firstName}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="surname">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control className={!!state.errors?.surname ? 'is-invalid':''} type="text" placeholder="Surname"  name="surname" onChange={handleChange}/>
                                <div className="invalid-feedback font-normal">
                                    {state.errors.surname}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="bold align-items-start">
                        <Col xs={6}>
                            <Form.Group className="mb-3" controlId="financial">
                                <Form.Label>Financial Status</Form.Label>
                                <Form.Select title="Choose" name="financial" className={!!state.errors?.financial ? 'is-invalid':''} onChange={handleChange}>
                                    <option value={null} className="text-muted">Choose ...</option>
                                    <option>Mortgage Approved</option>
                                    <option>Seeking a Mortgage</option>
                                    <option>Cash Buyer</option>
                                    <option>Moving Home</option>
                                </Form.Select>
                                <div className="invalid-feedback font-normal">
                                    {state.errors.financial}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="buyer">
                                <Form.Label>Buyer Status</Form.Label>
                                <Form.Select title="Choose" name="buyer" className={!!state.errors?.buyer ? 'is-invalid':''} onChange={handleChange}>
                                    <option value={null} className="text-muted">Choose ...</option>
                                    <option>First Time Buyer</option>
                                    <option>Trading Up</option>
                                    <option>Trading Down</option>
                                    <option>Investor</option>
                                </Form.Select>
                                <div className="invalid-feedback font-normal">
                                    {state.errors.buyer}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="bold align-items-start">
                        <Col xs={7}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" placeholder="Email Address" name="email" className={!!state.errors?.email ? 'is-invalid':''} onChange={handleChange} />
                                <div className="invalid-feedback font-normal">
                                    {state.errors.email}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="tel" placeholder="Phone" name="phone" className={!!state.errors?.phone ? 'is-invalid':''} onChange={handleChange}/>
                                <div className="invalid-feedback font-normal">
                                    {state.errors.phone}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="bold align-items-start">
                        <Col xs={7}>
                            <Form.Group className="mb-3" controlId="home">
                                <Form.Label>Preferred Home Type</Form.Label>
                                <Form.Select title="Choose" name="home" className={!!state.errors?.home ? 'is-invalid':''} onChange={handleChange}>
                                    <option value={null} className="text-muted">Choose ...</option>
                                    <option>Detached</option>
                                    <option>Semi-Detached</option>
                                    <option>Mid Terrace</option>
                                    <option>End Of Terrace</option>
                                    <option>Duplex</option>
                                </Form.Select>
                                <div className="invalid-feedback font-normal">
                                    {state.errors.home}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="bedrooms">
                                <Form.Label>No. of Bedrooms</Form.Label>
                                <Form.Select title="Choose" name="bedrooms" className={!!state.errors?.bedrooms ? 'is-invalid':''} onChange={handleChange}>
                                    <option value={null} className="text-muted">Choose ...</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                </Form.Select>
                                <div className="invalid-feedback font-normal">
                                    {state.errors.bedrooms}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {state.status === "submitting" && (
                        <div className="position-absolute bg-light" style={{top:0,left:0,right:0,bottom:0}}>
                            <Preloader/>
                        </div>
                    )}
                    {state.status === "submitted" && (
                        <div className="position-absolute bg-light" style={{top:0,left:0,right:0,bottom:0}}>
                            <Card.Body>
                                <h4 className="text-center px-4 text-info py-3 bold">Thank You!</h4>
                                <h5 className="text-center px-4">An agent will be in contact with you regarding your interest in the The Bawnogues development</h5>
                                {state.needsMortgage && (
                                    <Card className="bg-light">
                                        <Card.Footer className="p-3">
                                            <p>We note that you don't have a mortgage in place.</p>

                                            <p>We have partnered with Suss'd Mortgages to make it as easy as possible to get help with financing your home</p>



                                        </Card.Footer>
                                        <a className="btn btn-info btn-lg d-flex w-100 flex-column py-3 align-items-center"  rel="noreferrer" href="https://apply.sussd.ie/register">
                                            <span>Apply for a mortgage with</span>
                                            <SussdLogo className="ps-2" fill={'#ffffff'} width={100}/>
                                        </a>


                                    </Card>

                                )}
                            </Card.Body>

                        </div>
                    )}
                </Card.Body>
                <Card.Footer  className='text-end'>
                    <button className={`btn btn-primary my-3 ${state.status!==null ? 'opacity-0':''}`}>Submit</button>
                </Card.Footer>

            </Form>

        </Card>
    );
}

export default RegisterInterest;