import React from 'react';

function Preloader(props) {
    return (
        <div className="h-100 w-100 text-center">
            <div className="preloader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    );
}

export default Preloader;