import React, {useContext} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {RatesContext} from "../RatesProvider";
import RatesCard from "./RatesCard";

function ResultsContainer(props) {
    const context = useContext(RatesContext)
    const approvedRatesFromValues = (values) => {
        return context.getApprovedRates(values.value,values.loan,values.term)
    }
    const sortByRate = (rates)=>{
        return rates.sort((a, b) => {
            if (a.rate - b.rate !== 0) return a.rate - b.rate
            return b.fixed - a.fixed
        });
    }
    const sortByTerm = (rates)=>{
        return rates.sort((a, b) => {
            if (b.fixed - a.fixed !== 0) return b.fixed - a.fixed
            return a.rate - b.rate
        });
    }

    let allApproved = approvedRatesFromValues(props.values);
    if(!allApproved.error && props.values.fhs) {
        allApproved = allApproved.filter(rate => {
            return ["boi", "haven", "ptsb"].includes(rate.lender)
        })
    }

    if (Array.isArray(allApproved)){
        const overwritten = allApproved.map(item=>{
            item.monthly = context.calcMonthlyPayment(props.values.loan, props.values.term, item.rate)
            return item
        })
        const lowestFixedProps = {
            key:props.childKey + '2',
            delay: 2,
            variant: 'success',
            rates: sortByRate(overwritten.filter(item => item.fixed > 0)),
            title: 'Lowest Fixed Rates',
            term: props.values.term
        }
        const longestFixedProps = {
            key:props.childKey + '3',
            delay: 4,
            variant: 'primary',
            rates: sortByTerm(overwritten.filter(item => item.fixed > 0 && item.fixed <= props.values.term)),
            title: 'Longest Fixed Terms',
            term: props.values.term
        }
        const lowestVariableProps ={
            key:props.childKey + '1',
            delay: 0,
            variant: 'info',
            rates: sortByRate(overwritten.filter(item => item.fixed === 0)),
            title: 'Lowest Variable Rates',
            term: props.values.term
        }

            return (
                <div className="results-container">
                <Tabs
                    defaultActiveKey="Lowest Fixed Rates"
                    className="d-flex flex-nowrap rates-group-tab"
                    fill
                >
                    {[lowestFixedProps, longestFixedProps,lowestVariableProps].map(card=>{
                        return (
                            <Tab className="h-100 bg-white" key={card.title} eventKey={card.title} title={<small className="bold">{card.title}</small>}>
                                <RatesCard {...card}/>
                            </Tab>
                        )
                    })}
                </Tabs>
                    <hr className="m-0"/>
                    <div  className="bg-white p-3">
                        <small className="tiny p-0 m-0 d-block">
                            WARNINGS: Your home is at risk if you do not keep up payments on a mortgage or any other loan secured on it • You may have to pay charges if you pay off a fixed-rate loan early.
                        </small>
                    </div>

                </div>
            )
    }
    return <div className="text-center pb-5"><h4>Oh no!</h4><p>We couldn't find any rates for you</p><p>{allApproved.error}</p></div>




}

export default ResultsContainer;