import React, {useState}from 'react';
import {FaAngleDoubleRight} from "react-icons/fa";
import {Cookies} from "react-cookie-consent";
import CookieConsent from "./cookie-consent/CookieConsent";
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import {ListGroup, ListGroupItem, Button, Collapse, Form} from "react-bootstrap";
function CookieConsentBanner(props) {
    const location = useLocation()
    const [state,setState] = useState({
        expanded:false,
        permissions: {
            statistics:true
        }
    })
    const handleOnAccept = ()=>{
        if (state.permissions.statistics){
            Cookies.set("gatsby-gdpr-google-tagmanager", true, { expires: 150 })
            Cookies.set("gatsby-gdpr-facebook-pixel", true, { expires: 150 })
            initializeAndTrack(location)
        }
    }

    const cookieContent = (buttons) =>{
        return (<>

                <div className="d-flex flex-column flex-sm-row align-items-center mb-5">

                        <h2 className="bold mb-3 mb-sm-0">We use cookies!</h2>

                        <div className="d-flex ms-sm-auto">
                            <Button className="text-nowrap me-2 btn-lg bold" variant="dark" onClick={()=>{setState({...state, expanded: !state.expanded})}} >
                                Set Preferences
                            </Button>
                            {buttons}
                        </div>

                </div>




                <h6 className="small mt-2 sm">
                    This site uses cookies which are essential for the website to function properly.
                    We may place cookies for analysis of our visitor data, to improve our website and measure advertising performance.
                    Overall this data is used to provide a better website experience.
                    To learn more about cookies and how they're used, visit <a rel="noreferrer" className="text-light" href="https://www.cookiesandyou.com"> cookiesandyou.com </a>
                    To read more about how we use your data read our <a rel="noreferrer" href={'https://www.sussd.ie/legal/privacy'} className="text-light"> Privacy Policy </a>
                </h6>
                <Collapse in={state.expanded}>
                    <ListGroup className="mt-3 small">
                        <ListGroupItem className="bg-dark text-white">

                            <Form.Switch
                                id="statistics"
                                checked={state.permissions.statistics}
                                label="Statistical Analytics Cookies"
                                onChange={() => {
                                    setState({
                                        ...state,
                                        permissions: {
                                            ...state.permissions,
                                            statistics: !state.permissions.statistics
                                        }
                                    })
                                }}/>
                        </ListGroupItem>
                        <ListGroupItem className="bg-dark text-white">
                            <Form>
                                <Form.Switch
                                    id="necessary"
                                    label="Necessary Cookies (Required)"
                                    checked={true}
                                    readOnly={true}
                                />
                            </Form>
                        </ListGroupItem>
                    </ListGroup>
                </Collapse>
            </>)


    }

    return (
        <CookieConsent
            location="bottom"
            buttonText={<span className="text-nowrap">Accept {state.permissions.statistics ? 'Default':'Selected'} <FaAngleDoubleRight/></span>}
            cookieName="sussdWebsiteAcceptedCookie"
            containerClasses={["py-5"]}
            style={{ background: "#2B373B" }}
            buttonClasses={["btn btn-success text-white bold btn-lg"]}
            declineButtonClasses={["btn btn-lg btn-smoke"]}
            disableButtonStyles
            contentStyle={{margin:'3rem 0'}}
            expires={150}
            onAccept={handleOnAccept}
            // hack to allow send content directly to CookieConsent
            declineButtonText={cookieContent}
        >
        </CookieConsent>
    );
}

export default CookieConsentBanner;