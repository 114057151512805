function lenderUrl(lender) {
    let url = '/assets/images/banks/'
    switch (lender) {
        case 'aib' :
            url += 'aib.png';
            break
        case 'boi' :
            url += 'boi.svg';
            break
        case 'avant' :
            url += 'avant.svg';
            break
        case 'fi' :
            url += 'fi.svg';
            break
        case 'haven' :
            url += 'haven.png';
            break
        case 'ics' :
            url += 'ics.png';
            break
        case 'ptsb' :
            url += 'ptsb.png';
            break
        default:
    }
    return url;
}
export default lenderUrl


