import React from 'react';
import {Container} from "react-bootstrap";
function Header(props) {
    return (
        <>


            <div className="bg-banner mt-md-5 " style={{backgroundImage: `url("/assets/images/header.jpg")`}}>
                <div className="curve">

                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 54.521"
                         preserveAspectRatio="none">

                        <path id="Path_1" data-name="Path 1"
                              d="M0,151s603.918-22.615,1083.918-36.246S1920,96.479,1920,96.479V151Z"
                              transform="translate(0 -96.479)" fill="#fff"/>


                    </svg>
                </div>
                <Container
                    className="px-xs-0-only text-center position-relative d-flex align-items-center justify-content-end justify-content-sm-start">
                    <div className="bg-info py-3 pt-5 pt-md-3 px-1 px-sm-3" style={{marginTop:'-1.5rem'}}>
                        <div className="img-logo" style={{backgroundImage: 'url("/assets/images/logo.svg")'}} />
                    </div>


                    {/*<img src="https://nc63jbq4aqqjq3scymfvbaypky0oyqnx.lambda-url.eu-west-1.on.aws/" alt="Developer Logo" width={300} height={235}/>*/}
                </Container>


            </div>
        </>
    );
}

export default Header;