import React from 'react';

function Disclaimer(props) {
    return (
        <div className="small py-3">
            <p>This web page is for illustrative purposes only, so as to give you, the borrower, an overview of the potential cost of borrowing.
                Our calculators give indicative figures. They are in no way intended to constitute an offer. Such calculations are made on the presumption that the information supplied by you is accurate and is subject to approval by the actual lender.</p>
            <p>Truss Mortgages Limited t/a Suss'd Mortgages is regulated by the Central Bank of Ireland. Company Registration Number 690100</p>
        </div>
    );
}

export default Disclaimer;