import React, {useState} from 'react';
import {Button, Collapse, ListGroup, ListGroupItem} from "react-bootstrap";

function FirstTimeBuyers(props) {
    const [state,setState] = useState({
        htbsCollapsed: true,
        fhsCollapsed: true,
    })
    return (
        <div>


            <h5 className=" mt-5">Help To Buy Scheme</h5>
            <p className="mb-1">This is a tax rebate of up to €30,000 available to first time buyers to help towards their new home purchase. The amount received is dependent on tax paid over the previous 4 years. You must be fully tax compliant. You can apply online through <a href="https://www.ros.ie/myaccount-web/sign_in.html?execution=e2s1">Revenue’s MyAccount Service</a> </p>
            <Button variant="primary" size="sm"  onClick={()=>{setState({...state, htbsCollapsed: !state.htbsCollapsed})}}>
                Find out more
            </Button>
            <Collapse in={!state.htbsCollapsed}>
                <ListGroup className="mt-3 small">
                    <ListGroupItem>You must be a first-time buyer. If you bought a home outside Ireland already you will not qualify. If your spouse already has purchased a house but you are a First Time Buyer, you can only qualify if you are the sole mortgage applicant</ListGroupItem>
                    <ListGroupItem>Property must be a new build and the builder must be registered as a qualifying contractor.</ListGroupItem>
                    <ListGroupItem>Maximum Relief is 10% of the value of the property or €30,000 whichever is the lower</ListGroupItem>
                    <ListGroupItem>Maximum Property Purchase is €500,000</ListGroupItem>
                    <ListGroupItem>You must take out a mortgage for at least 70% of the purchase price and the loan cannot have a guarantor.</ListGroupItem>
                    <ListGroupItem>	You must live in the property. The rebate can be clawed back if you don’t live in the property for 5 years.</ListGroupItem>
                    <ListGroupItem>You don’t require a deposit, but in practice a lender will want to see a deposit saved.</ListGroupItem>
                </ListGroup>
            </Collapse>

            <h5 className=" mt-5">First Home Scheme</h5>
            <p className="mb-1">The First Home Scheme helps first time buyers with up to 30% of the cost of their new home.
                The state and participating banks pay up to 30% of the market value of your new home in return for an equivalent equity stake in the property.
                The maximum price of a property you can purchase depends on the local authority area in which you purchase your new home.
                You can apply online on the <a href="https://application.firsthomescheme.ie/main">First Home Scheme Website</a>
            </p>
            <Button variant="primary" size="sm"  onClick={()=>{setState({...state, fhsCollapsed: !state.fhsCollapsed})}}>
                Find out more
            </Button>
            <Collapse in={!state.fhsCollapsed}>
                <ListGroup className="mt-3 small">
                    <ListGroupItem>
                        To qualify you must be a first-time buyer having never bought or built a property in Ireland or abroad. If you have previously owned a home but no longer have a financial interest in any property, you may qualify as a fresh start applicant if:
                        <ul>
                            <li>You are now divorced, separated or your relationship has ended</li>
                            <li>You have gone through personal insolvency or bankruptcy</li>
                        </ul>
                    </ListGroupItem>
                    <ListGroupItem>
                        You must have a minimum deposit of 10% of the property purchase price if you are a first-time buyer, or 20% if you are an eligible homebuyer
                    </ListGroupItem>
                    <ListGroupItem>
                        The max amount you can receive towards your property purchase is 30% but this is reduced to 20% if you are also benefiting form the help to buy scheme
                    </ListGroupItem>
                    <ListGroupItem>
                        You must borrow the maximum mortgage amount available to you from a Participating Lender
                    </ListGroupItem>
                    <ListGroupItem>
                        The minimum amount you can get is 2.5% of the property purchase price or €10,000, whichever is the higher
                    </ListGroupItem>
                    <ListGroupItem>
                        The maximum price of a property you can purchase depends on the local authority area in which you purchase your new home. The price caps range from €250,000 up to €500,000
                    </ListGroupItem>
                    <ListGroupItem>
                        There is no charge for the first 6 years. The charge is 1.75% for years 6 to 15, 2.15% for years 16 to 29 and 2.85% for years 30+.
                    </ListGroupItem>
                    <ListGroupItem>
                        The equity element can be redeemed at any time.
                    </ListGroupItem>
                </ListGroup>
            </Collapse>

        </div>
    );
}

export default FirstTimeBuyers;