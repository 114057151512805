import React, { useRef, useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Inputs from "./Inputs3";
import ResultsContainer from "./ResultsContainer";
import {FaAngleDoubleRight} from "react-icons/fa";
import Disclaimer from "../Disclaimer";
import axios from "axios";
import {setCookie} from "../../assets/js/cookie";
const base64 = require('base-64');
function CalculatorAccordion() {
    const [state, setState] = useState({
        values: false,
        activeKey: 0,
        valuesKey: null,
        submitting: false,
        submitTries: 0,
        submitSuccess: false
    })
    // const [apply, setApply] = useState({
    //     values: {},
    //     errors: {},
    // })

    const accordion = useRef()
    const handleSelect = (k) => {
        console.log(k)
        if (k === 1 && (!state.values?.loan || !state.values?.price || !state.values.term)) return
        //if (k !== null)
            setState({...state, activeKey: k})
        if (k === 1 && window.innerWidth < 500) accordion.current.scrollIntoView()
    }
    const setValues = (price, loan, term, fhs, deposit, shared, rebate, ftb, htbs) => {
        setState(prev=>{
            let values = {
                price: price,
                loan: loan,
                term: term,
                fhs: fhs,
                deposit: deposit,
                shared: shared,
                rebate: rebate,
                ftb: ftb,
                htbs: htbs
            }
            lambdaSaveCalculation(values)
            setCookie(values)
            return {
                ...prev,
                values: values,
               // valuesKey: price * loan * term * (fhs ? 1 : 2)
            }

        })
    }
    const setTerm = (e) => {
        setState({
            ...state,
            values: {
                ...state.values,
                term: e.target.value
            }

        })
    }
    // const setAnswer = (name, value) => {
    //     let prev = {...apply}
    //     setApply(() => {
    //         prev.values[name] = value
    //         prev.errors[name] = false
    //         return prev
    //     })
    // }
    // const checkApplyErrors = () => {
    //     let errors = {}
    //     if (!apply.values?.applicants) {
    //         errors.applicants = "Select how many applicants"
    //     }
    //     if (!apply.values?.app1Name || apply.values.app1Name === '') {
    //         errors.app1Name = "Enter your full name"
    //     }
    //     if (!(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+))/.test(apply.values.app1Email))) {
    //         errors.app1Email = "Enter a valid email address"
    //     }
    //     if (!apply.values?.action) {
    //         errors.action = "Select an action"
    //     }
    //     if (!apply.values['contact-agreement']) {
    //         errors['contact-agreement'] = "You must agree to be contacted"
    //     }
    //     return errors
    // }
    // const handleSubmit = () => {
    //     let errors = checkApplyErrors()
    //     if (Object.keys(errors).length) {
    //         setApply({
    //             ...apply,
    //             errors: errors
    //         })
    //         return
    //     }
    //     setState({...state, submitting: true, submitTries: 1})
    // }
   // let registerTimer
    // const register = () => {
    //     let params = {
    //         ...state.values,
    //         ...apply.values,
    //         external: 'hallmark',
    //         type: 'external',
    //         source: base64.encode(window.location.href)
    //     }
    //     const endpoint = 'https://itdhvdcdl4ozbvsic34gs6kxzu0okfdm.lambda-url.eu-west-1.on.aws/'
    //     axios.post(endpoint, JSON.stringify(params)).then(response => {
    //         setState({...state, submitting: false, submitSuccess: true, submitTries: 0})
    //     }).catch(error => {
    //         clearTimeout(registerTimer)
    //         registerTimer = setTimeout(() => {
    //             setState({...state, submitTries: state.submitTries + 1})
    //         })
    //         console.log('Show error notification!')
    //         return Promise.reject(error)
    //     })
    // }
    const lambdaSaveCalculation = (data) => {
        let sessionID = localStorage.getItem('sussd-session')
        if (sessionID && !!data.loan){
            const params = {
                type: 'calculation',
                data: {
                    ...data,
                    source: base64.encode(window.location.href),
                    sessionID: sessionID
                }
            }
            const endpoint = 'https://itdhvdcdl4ozbvsic34gs6kxzu0okfdm.lambda-url.eu-west-1.on.aws/'
            axios.post(endpoint, JSON.stringify(params))
        }

    }
    // useEffect(() => {
    //     if (state.submitTries > 0) {
    //         if (state.submitTries < 4) {
    //             register()
    //         } else {
    //             setState({...state, submitting: false})
    //         }
    //     }
    // }, [state.submitTries])
    return (
        <div className="sticky-top-0" ref={accordion}>
            <Accordion onSelect={handleSelect} activeKey={state.activeKey} className="pt-3">
                <Accordion.Item eventKey={0}>
                    <Accordion.Header className="h5 mb-0 bold">Calculate your mortgage</Accordion.Header>
                    <Accordion.Body className="p-0">
                        <Inputs setValues={setValues} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={1}>
                    <Accordion.Header className="h5 mb-0 bold">Get your results</Accordion.Header>
                    <Accordion.Body className="p-0 pt-3 bg-light">
                        {(state.values?.loan && state.values?.price && state.values?.term) && (
                            <React.Fragment key={state.valuesKey}>
                                <div className="px-3 pb-3">
                                    <div className="d-flex small justify-content-between">
                                        <label htmlFor="termRange" className="form-label bold">Borrow
                                            €{state.values.loan.toLocaleString("en-US")} </label>
                                        <label className="form-label bold">over {state.values.term} years</label>
                                    </div>
                                    <input type="range" onChange={setTerm} className="form-range mb-0" id="termRange"
                                           min={5} max={35} value={state.values.term}/>
                                    <div className="d-flex small justify-content-between"
                                         style={{position: 'relative', top: '-6px'}}>
                                        <small>Min 5 years</small>
                                        <small>Max 35 years</small>
                                    </div>
                                </div>

                                <ResultsContainer childKey={'rc' + state.valuesKey} values={{
                                    value: state.values.price,
                                    term: state.values.term,
                                    loan: state.values.loan,
                                    fhs: state.values.fhs
                                }}/>
                                {/*<h5 className="mb-0">*/}
                                {/*    <a href="https://www.sussd.ie/apply-now" className="lh-1 btn btn-lg btn-primary accordion-btn w-100 bold br-tl-0 br-tr-0 text-start">Apply Now <FaAngleDoubleRight/> </a>*/}
                                {/*</h5>*/}
                                <h5><a href="https://apply.sussd.ie/register" className="lh-1 btn btn-lg btn-primary accordion-btn w-100 bold br-tl-0 br-tr-0 text-start"> Apply Today <FaAngleDoubleRight/></a></h5>
                            </React.Fragment>
                        )}

                    </Accordion.Body>
                </Accordion.Item>
                {/*{*/}
                {/*    state.activeKey !== 0 && (*/}
                {/*        <Accordion.Item eventKey={2}>*/}
                {/*            <Accordion.Header className="h5 mb-0 bold">Apply now</Accordion.Header>*/}
                {/*            <Accordion.Body className="p-0">*/}
                {/*                <div className="position-relative">*/}
                {/*                    <Apply answers={apply} saveAnswer={setAnswer}/>*/}
                {/*                    <div className={`apply-overlay ${state.submitting ? '':'d-none'}`} >*/}
                {/*                        <Preloader/>*/}
                {/*                    </div>*/}
                {/*                    <div className={`apply-overlay ${state.submitSuccess ? '':'d-none'}`} >*/}
                {/*                        <div className="text-center mx-auto">*/}
                {/*                            <p>Details Submitted</p>*/}
                {/*                            <AnimatedSuccess/>*/}
                {/*                            <p>The team at Suss'd will be in touch with you shortly</p>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <h5 className="mb-0">*/}
                {/*                    <button disabled={state.submitting || state.submitSuccess} onClick={handleSubmit}*/}
                {/*                            className="lh-1 btn btn-lg btn-primary accordion-btn w-100 bold br-tl-0 br-tr-0 text-start"> Submit <FaAngleDoubleRight/>*/}
                {/*                    </button>*/}
                {/*                </h5>*/}
                {/*            </Accordion.Body>*/}

                {/*        </Accordion.Item>*/}
                {/*    )*/}
                {/*}*/}

            </Accordion>
            <div className="px-3 px-sm-0">
            <Disclaimer/>
            </div>
            <div className="pb-3 px-3 px-sm-0">
                <h3 className="">Apply For A Mortgage with Suss'd</h3>



                <p className="mb-1">Suss'd are a digital mortgage broker who use the latest technology to
                    make getting a mortgage easy.
                    Suss'd will help you arrange a mortgage to purchase your new home. So why use a Mortgage
                    Broker? With Suss'd you get access
                    to the entire mortgage market with one online application, and they will help you with
                    all the paperwork, provide expert advice and it’s all for free! </p>
                <a className="btn btn-lg btn-primary bold mt-3" href="https://www.sussd.ie/apply-now">
                    Apply now
                </a>
            </div>
        </div>
    );
}

export default CalculatorAccordion;