// import {getCookieConsentValue, getLegacyCookieName} from "react-cookie-consent/src/utilities";
// import {Cookies} from "react-cookie-consent";
// const cookieName = 'calcvals'
// const expires=2;
// const sameSite="strict"
// const extraCookieOptions={domain:'.sussd.ie'}
// export const getCookie = () => {
//     return JSON.parse(getCookieConsentValue(cookieName));
// }
// export const setCookie = (cookieValue) => {
//     let cookval = JSON.stringify(cookieValue)
//     let cookieSecurity = window.location ? window.location.protocol === "https:" : true;
//     const cookieOptions = { expires, ...extraCookieOptions, sameSite, secure: cookieSecurity };
//
//     // Fallback for older browsers where can not set SameSite=None,
//     // SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
//     if (sameSite === "none") {
//         Cookies.set(getLegacyCookieName(cookieName), cookval, cookieOptions);
//     }
//
//     // set the regular cookie
//     Cookies.set(cookieName, cookval, cookieOptions);
// }

import {Cookies} from "react-cookie-consent";
const cookieName = 'calcvals'
const expires=2;
const sameSite="strict"
const extraCookieOptions={domain:'.sussd.ie'}
export const cookieLegacyName = `${cookieName}-legacy`

export const getCookie = () => {
    let cookieValue = Cookies.get(cookieName);
    // if the cookieValue is undefined check for the legacy cookie
    if (cookieValue === undefined) {
        cookieValue = Cookies.get(cookieLegacyName);
    }
    if (cookieValue === undefined) {
        return false
    }
    return JSON.parse(cookieValue);
}
export const setCookie = (cookieValue) => {
    let cookval = JSON.stringify(cookieValue)
    let cookieSecurity = window.location ? window.location.protocol === "https:" : true;
    const cookieOptions = { expires, ...extraCookieOptions, sameSite, secure: cookieSecurity };

    // Fallback for older browsers where can not set SameSite=None,
    // SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
    if (sameSite === "none") {
        Cookies.set(cookieLegacyName, cookval, cookieOptions);
    }

    // set the regular cookie
    Cookies.set(cookieName, cookval, cookieOptions);
}