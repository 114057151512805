import React, {useEffect, useState} from 'react';
import {ListGroup, ListGroupItem} from "react-bootstrap";
function Siteplan(props) {
    const [state,setState] = useState({loaded:false})
    useEffect(()=>{
        setState(s=>{return {...s,loaded:true}})
    },[])
    return (
        <div>
            <ListGroup className="px-3 px-sm-0 col-12 col-lg-5 col-xl-4">
                {Object.keys(props.homes).map(h=>{
                    return (
                        <ListGroupItem key={'list-item-' + props.homes[h].title} className="d-flex align-items-center">
                            <span className="me-3 rounded-pill flex-shrink-0" style={{height:'40px',width:'40px',backgroundColor:props.homes[h].color}}/>
                            <h6 className="me-3" style={{width:'80px'}}>{props.homes[h].title}</h6>
                            <div className="d-flex flex-column">

                                <small>{props.homes[h].desc}</small>
                                <small>{props.homes[h].size}</small>
                            </div>
                        </ListGroupItem>

                    )

                })}
            </ListGroup>
            <div className="d-flex justify-content-center " >
                <div className="d-lg-block siteplan-adjust">
                    <img src={"/assets/images/plans/site-plan-p-1080.png"} alt="Site Plan" />
                </div>
            </div>

        </div>
    );
}

export default Siteplan;