import React, {useEffect, useState} from 'react';
import {Col, Form, ListGroup, Row} from "react-bootstrap";
import NumberFormat from "react-number-format";
import {FaInfoCircle} from "react-icons/fa";
import TinyPopOver from "../TinyPopOver";

function Inputs3(props) {
    const [state, setState] = useState({
        values: {
            term: 30,
            price: 385000,
            deposit: null,
            cc: "Cork County"
        },
        errors: {},
        defaultValues: {}
    })
    const [depositDisplayValue, setDepositDisplayValue] = useState('')
    const counties = [
        {
            "name": "Carlow",
            "apartment": false,
            "house": "325000",
            "text": "Carlow County Council"
        },
        {
            "name": "Cavan",
            "apartment": false,
            "house": "325000",
            "text": "Cavan County Council"
        },
        {
            "name": "Clare",
            "apartment": false,
            "house": "325000",
            "text": "Clare County Council"
        },
        {
            "name": "Cork City",
            "apartment": "500000",
            "house": "475000",
            "text": "Cork City Council"
        },
        {
            "name": "Cork County",
            "apartment": false,
            "house": "425000",
            "text": "Cork County Council"
        },
        {
            "name": "Donegal",
            "apartment": false,
            "house": "325000",
            "text": "Donegal"
        },
        {
            "name": "Dublin City",
            "apartment": "500000",
            "house": "475000",
            "text": "Dublin City Council"
        },
        {
            "name": "Dublin South",
            "apartment": "500000",
            "house": "475000",
            "text": "Dublin South County Council"
        },
        {
            "name": "Dun Laoghaire-Rathdown",
            "apartment": "500000",
            "house": "475000",
            "text": "Dun Laoghaire-Rathdown County Council"
        },
        {
            "name": "Fingal",
            "apartment": "500000",
            "house": "475000",
            "text": "Fingal County Council"
        },
        {
            "name": "Galway City",
            "apartment": "450000",
            "house": "425000",
            "text": "Galway City Council"
        },
        {
            "name": "Galway County",
            "apartment": false,
            "house": "375000",
            "text": "Galway County Council"
        },
        {
            "name": "Kerry",
            "apartment": false,
            "house": "325000",
            "text": "Kerry County Council"
        },
        {
            "name": "Kildare",
            "apartment": false,
            "house": "425000",
            "text": "Kildare County Council"
        },
        {
            "name": "Kilkenny",
            "apartment": false,
            "house": "375000",
            "text": "Kilkenny County Council"
        },
        {
            "name": "Laois",
            "apartment": false,
            "house": "350000",
            "text": "Laois County Council"
        },
        {
            "name": "Leitrim",
            "apartment": false,
            "house": "325000",
            "text": "Leitrim County Council"
        },
        {
            "name": "Limerick",
            "apartment": "450000",
            "house": "375000",
            "text": "Limerick City and County Council"
        },
        {
            "name": "Longford",
            "apartment": false,
            "house": "325000",
            "text": "Longford County Council"
        },
        {
            "name": "Louth",
            "apartment": false,
            "house": "375000",
            "text": "Louth County Council"
        },
        {
            "name": "Mayo",
            "apartment": false,
            "house": "325000",
            "text": "Mayo County Council"
        },
        {
            "name": "Meath",
            "apartment": false,
            "house": "375000",
            "text": "Meath County Council"
        },
        {
            "name": "Monaghan",
            "apartment": false,
            "house": "325000",
            "text": "Monaghan County Council"
        },
        {
            "name": "Offaly",
            "apartment": false,
            "house": "325000",
            "text": "Offaly County Council"
        },
        {
            "name": "Roscommon",
            "apartment": false,
            "house": "325000",
            "text": "Roscommon County Council"
        },
        {
            "name": "Sligo",
            "apartment": false,
            "house": "325000",
            "text": "Sligo County Council"
        },
        {
            "name": "Tipperary",
            "apartment": false,
            "house": "325000",
            "text": "Tipperary County Council"
        },
        {
            "name": "Waterford",
            "apartment": "450000",
            "house": "350000",
            "text": "Waterford City and County Council"
        },
        {
            "name": "Westmeath",
            "apartment": false,
            "house": "375000",
            "text": "Westmeath County Council"
        },
        {
            "name": "Wexford",
            "apartment": false,
            "house": "325000",
            "text": "Wexford County Council"
        },
        {
            "name": "Wicklow",
            "apartment": false,
            "house": "475000",
            "text": "Wicklow County Council"
        }
    ]
    const minimumLoanAmount = 50000

    const calc = (prevState) => {

        let deposit = prevState.values?.deposit || 0, shared = 0, rebate = 0, loan = 0

        // REBATE
        if (prevState.values?.ftb && prevState.values?.htbs) {
            rebate = Math.min(30000, prevState.values.price * 0.1)
        }
        prevState.values.rebate = rebate
        prevState.defaultValues.rebate = rebate

        // DEPOSIT
        // we want deposit to calc value on the first run only
        //if (!prevState.defaultValues?.deposit && prevState.defaultValues.deposit !== 0) {
        if (prevState.values.deposit === null){
           deposit = prevState.values.price * 0.1
            if (prevState.values?.ftb) {
                if (prevState.values?.htbs) {
                    deposit = deposit - rebate
                }
            }
            prevState.values.deposit = deposit
            prevState.defaultValues.deposit = deposit
            setDepositDisplayValue(deposit)
        }

        // SHARED
        if (prevState.values?.ftb && prevState.values?.fhs) {
            let multiplier = prevState.values?.htbs ? 0.2 : 0.3
            shared = prevState.values.price * multiplier
            if (prevState.values?.htbs && deposit){
                shared -= deposit
            }
        }
        prevState.values.shared = shared
        prevState.defaultValues.shared = shared

        //LOAN
        loan = prevState.values.price - deposit
        if (prevState.values?.ftb) {
            if (prevState.values?.fhs && prevState.values?.cc && shared > 0) {
                loan -= shared
            }
            if (prevState.values?.htbs) {
                loan -= rebate
            }
        }
        prevState.values.loan = loan
        prevState.defaultValues.loan = loan

    }

    const getAnyErrors = (prevState, name) => {
        // assumes changes have already been affected

        // county council
        if (prevState.values?.ftb && prevState.values?.fhs && !prevState.values?.cc) {
            return {
                name: "cc",
                error: "A selection is required",
                info: "Under the First Home Scheme, the local council will determine the maximum property price allowed"
            }
        }

        // price limited by cc
        if (prevState.values?.ftb && prevState.values?.fhs && prevState.values?.cc) {
            let county = counties.find(item => item.name === prevState.values.cc)
            let maximum = county.house
            if (county.apartment && prevState.values?.type === "apartment") {
                maximum = county.apartment
            }
            if (prevState.values.price > maximum) {
                let maxText = parseInt(maximum).toLocaleString("en-US")
                let properties = prevState.values?.type ? prevState.values.type : 'propertie'
                return {
                    name: "price",
                    error: `The maximum is €${maxText}`,
                    info: `For the First Home Scheme, ${county.text} has a maximum price of €${maxText} for new ${properties}s`
                }
            }
        }

        // price limited by rebate
        if (prevState.values?.ftb && prevState.values?.htbs) {
            if (prevState.values.price > 500000) {
                return {
                    name: "price",
                    error: `The maximum is €500,000`,
                    info: `The Help To By Scheme allows a maximum property price of €500,000`
                }
            }
        }

        // price limited by upper limit
        if (prevState.values.price > 5000000) {
            return {
                name: "price",
                error: `The maximum is €5,000,000`,
                info: `We are only doing calculations for properties under €5,000,000 right now`
            }
        }

        // price limited by lower limit
        let minPrice = Math.ceil(minimumLoanAmount * 1.11111)
        if (prevState.values.price < minPrice) {
            return {
                name: "price",
                error: `The minimum is €${minPrice.toLocaleString("en-US")}`,
                info: `We can't help you with loan amounts less than €${minimumLoanAmount.toLocaleString("en-US")}`
            }
        }

        // deposit limited by minimum mortgage (mort must be at least 70% price)
        if (prevState.values?.ftb && prevState.values?.htbs) {
            let minMortgage = prevState.values.price * 0.7
            if (prevState.values.loan < minMortgage) {
                let maximum = prevState.values.deposit - (minMortgage - prevState.values.loan)
                return {
                    name: "deposit",
                    error: `The maximum is €${maximum.toLocaleString("en-US")}`,
                    info: `The resulting loan amount of €${prevState.values.loan.toLocaleString("en-US")} is less than the minimum required by the Help To Buy Scheme. The minimum loan amount is 70% of the property price (€${minMortgage.toLocaleString("en-US")})`
                }
            }
        }

        // deposit limited by fhs value - min of greater of 2.5% or 10000
        if (prevState.values?.ftb && prevState.values?.fhs) {
            let perc = prevState.values.price * 0.025
            let minShared = Math.max(10000, perc)
            if (prevState.values.shared < minShared) {
                let diff = minShared - prevState.values.shared
                let depMax = prevState.values.deposit - diff
                return {
                    name: "deposit",
                    error: `The maximum is €${depMax.toLocaleString("en-US")}`,
                    info: `Under the First Home Scheme, the minimum you can take is the higher of €10,000 or 2.5% of the property price (€${minShared.toLocaleString("en-US")})`
                }
            }
        }

        // deposit limited by fhs minimum of 10% price
        if (prevState.values?.ftb && prevState.values?.fhs) {
            let depMin = prevState.values.price * 0.1
            let dep2Min = depMin
            if (prevState.values?.htbs) {
                dep2Min -= prevState.values.rebate
            }
            if (prevState.values.deposit < dep2Min) {
                return {
                    name: "deposit",
                    error: `The minimum is €${dep2Min.toLocaleString("en-US")}`,
                    info: `Under the First Home Scheme, the minimum deposit is 10% of the purchase price (€${depMin.toLocaleString("en-US")})${prevState.values?.htbs ? ` less the amount from the Help To Buy Scheme (€${prevState.values.rebate.toLocaleString("en-US")})` : ''}`
                }
            }
        }

        // deposit limited by min loan amount
        let minDeposit = prevState.values.price * 0.1
        let maxDeposit = prevState.values.price - minimumLoanAmount
        if (prevState.values?.ftb) {
            if (prevState.values?.fhs) {
                minDeposit -= prevState.values.shared
                maxDeposit -= prevState.values.shared
            }
            if (prevState.values?.htbs) {
                minDeposit -= prevState.values.rebate
                maxDeposit -= prevState.values.rebate
            }
        }
        if (minDeposit > prevState.values.deposit) {
            let maxLoan = (prevState.values.price * 0.9).toLocaleString("en-US")
            return {
                name: "deposit",
                error: `The minimum is €${minDeposit.toLocaleString("en-US")}`,
                info: `Lenders will only loan up to 90% of the property value (€${maxLoan})`
            }
        }
        if (maxDeposit < prevState.values.deposit) {
            return {
                name: "deposit",
                error: `The maximum is €${maxDeposit.toLocaleString("en-US")}`,
                info: `We can't help you with loan amounts less than €${minimumLoanAmount.toLocaleString("en-US")}`
            }
        }
        return false
    }

    const doValueChangeSideEffects = (prevState, name) => {
        if (prevState.values?.price) {
            prevState.errors = {}
            if (name==='htbs'){
                prevState.values.deposit = null
            }
            calc(prevState, name)

            let error = getAnyErrors(prevState)
            if (error) {
                prevState.errors[error.name] = <>{error.error} <TinyPopOver
                    open={true}
                    mykey={'pop-' + error.name + '-' + prevState.values[error.name]}
                    button={<FaInfoCircle
                        size={14}/>}>{error.info}</TinyPopOver> </>
            }
        }
        return prevState
    }

    let valueChangedTimer
    const onBlur = (e) => {
        clearTimeout(valueChangedTimer)
        handleOnAnswer(e.target.name, e.target.value)
    }
    const onType = (e) => {
        clearTimeout(valueChangedTimer)
        valueChangedTimer = setTimeout(() => {
            handleOnAnswer(e.target.name, e.target.value)
        }, 500)
    }
    const handleOnAnswer = (name, val) => {
        let value
        switch (name) {
            case "type" :
            case "cc":
                value = val === "null" ? null : val
                break
            default:
                value = ["true", "false"].includes(val) ? val === "true" : parseInt(val.replace(/\D/g, ''))
                if (isNaN(value)) {
                    value = 0
                }
        }

        setState(() => {
            let prevState = {...state}
            prevState.values[name] = value
            prevState.errors[name] = false
            doValueChangeSideEffects(prevState, name)
            return prevState
        })
    }
    const handleDepositChange = (e) => {
        setDepositDisplayValue(e.target.value)
        clearTimeout(valueChangedTimer)
        valueChangedTimer = setTimeout(() => {
           handleOnAnswer(e.target.name, e.target.value)
        }, 500)
    }



    /* REPORT NEW VALUES TO CALCULATOR */
    useEffect(() => {
        if (state.values?.price && state.values?.loan && state.values?.term) {
            bubbleValues()
        }
    }, [state])

    /* SAVE VALUES TO CALC  */
    const bubbleValues = ()=>{
        props.setValues(
            state.errors?.price ? null : state.values.price,
            state.errors?.deposit ? null : state.values.loan,
            state.values.term,
            state.values?.fhs,
            state.values.deposit,
            state.values?.fhs ? state.values.shared : 0,
            state.values?.htbs ? state.values.rebate : 0,
            state.values?.ftb,
            state.values?.htbs
        )
    }

    /* CALC ON FIRST RUN */
    useEffect(()=>{
        calc({...state})
        bubbleValues()
    },[])

    const propertyTypeDisplayed = () => {
        if (state.values?.ftb && state.values?.fhs && state.values?.cc) {
            let county = counties.find(item => item.name === state.values.cc)
            if (county.apartment) {
                return true
            }
        }
        return false
    }



    return (
        <Form noValidate>

        <ListGroup className="list-group-flush">
            {/*PROPERTY PRICE NUMBER*/}
            <ListGroup.Item className="bg-light">
                <Row className="bold  align-items-center">
                    <Col xs={5}>
                        Property Price
                    </Col>
                    <Col>
                        <NumberFormat
                            name="price"
                            onBlur={onBlur}
                            className={`text-end form-control bold ${state.errors?.price ? 'is-invalid' : ''}`}
                            allowNegative={false}
                            type="int"
                            prefix="€ "
                            decimalScale={0}
                            thousandSeparator={true}
                            pattern="\d*"
                            autoComplete="off"
                            onChange={onType}
                            defaultValue={state.values.price}
                        />
                        <div className="invalid-feedback font-normal">
                            {state.errors?.price}
                        </div>
                    </Col>
                </Row>
            </ListGroup.Item>

            {/*FIRST TIME BUYER RADIO*/}
            <ListGroup.Item className="bg-light">
                <Row className="bold py-2">
                    <Col xs={5}>
                        First Time Buyer
                    </Col>
                    <Col>
                        <div className="text-end">
                            <Form.Check inline type='checkbox' className="pe-5">
                                <Form.Check.Input autoComplete="off" name="ftb" value="true" id="ftb1" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="ftb1">{`Yes`}</Form.Check.Label>

                            </Form.Check>
                            <Form.Check inline type='checkbox' >
                                <Form.Check.Input autoComplete="off" name="ftb" value="false" id="ftb0" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="ftb0">{`No`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                        {!!state.errors?.ftb && (
                            <div className="invalid-feedback font-normal">
                                {state.errors.ftb}
                            </div>
                        )}

                    </Col>
                </Row>
            </ListGroup.Item>
            {/*TAX REBATE - HELP TO BUY SCHEME RADIO*/}
            <ListGroup.Item className={`bg-light ${state.values?.ftb ? '' : 'd-none'}`}>
                <Row className="bold py-2">
                    <Col xs={5}>
                        <div>Help To Buy Scheme</div>
                        <div className="tiny font-normal">Tax Rebate</div>
                    </Col>
                    <Col>
                        <div  className="text-end">
                            <Form.Check inline type='checkbox checkbox-success' className="pe-5">
                                <Form.Check.Input autoComplete="off" name="htbs" value="true" id="htbs1" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="htbs1">{`Yes`}</Form.Check.Label>
                            </Form.Check>
                            <Form.Check inline type='checkbox' >
                                <Form.Check.Input autoComplete="off" name="htbs" value="false" id="htbs0" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="htbs0">{`No`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                        {!!state.errors?.htbs && (
                            <div className="invalid-feedback font-normal">
                                {state.errors.htbs}
                            </div>
                        )}
                    </Col>
                </Row>
            </ListGroup.Item>
            {/*SHARED EQUITY - FIRST HOME SCHEME RADIO*/}
            <ListGroup.Item className={`bg-light ${state.values?.ftb ? '' : 'd-none'}`}>
                <Row className="bold py-2">
                    <Col xs={5}>
                        <div>First Home Scheme</div>
                        <div className="tiny font-normal">Shared Equity</div>
                    </Col>
                    <Col>
                        <div className="text-end">
                            <Form.Check inline type='checkbox' className="pe-5">
                                <Form.Check.Input autoComplete="off" name="fhs" value="true" id="fhs1" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="fhs1">{`Yes`}</Form.Check.Label>
                            </Form.Check>
                            <Form.Check inline type='checkbox'>
                                <Form.Check.Input autoComplete="off"
                                                  name="fhs" value="false" id="fhs0" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="fhs0">{`No`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                        {!!state.errors?.fhs && (
                            <div className="invalid-feedback font-normal">
                                {state.errors.fhs}
                            </div>
                        )}
                    </Col>
                </Row>
            </ListGroup.Item>
            {/*SHARED EQUITY - COUNTY COUNCIL SELECT*/}
            <ListGroup.Item className={`bg-light ${!!state.values?.ftb && !!state.values?.fhs ? '' : 'd-none'}`}>
                <Row className="bold  align-items-center">
                    <Col xs={5}>
                        County Council
                    </Col>
                    <Col>
                        <Form.Select autoComplete="off" name="cc"
                                     className={`bold ${state.errors?.cc ? 'is-invalid' : ''}`}
                                     onChange={onBlur}
                        defaultValue={state.values.cc}>
                            <option value="null">Select</option>
                            {counties.map(county => {
                                return (<option key={county.name} value={county.name}>{county.text}</option>)
                            })}
                        </Form.Select>
                        <div className="invalid-feedback font-normal">
                            {state.errors?.cc}
                        </div>
                    </Col>
                </Row>
            </ListGroup.Item>
            {/*SHARED EQUITY - PROPERTY TYPE RADIO*/}
            <ListGroup.Item className={`bg-light ${propertyTypeDisplayed() ? '' : 'd-none'}`}>
                <Row className="bold py-2">
                    <Col xs={5}>
                        Property Type
                    </Col>
                    <Col>
                        <div>
                            <Form.Check inline type='checkbox' className="pe-4">
                                <Form.Check.Input autoComplete="off" name="type" value="house" id="type1" type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="type1">{`House`}</Form.Check.Label>
                            </Form.Check>
                            <Form.Check inline type='checkbox'>
                                <Form.Check.Input autoComplete="off" name="type" value="apartment" id="type0"
                                                  type={'radio'}
                                                  onChange={onBlur}/>
                                <Form.Check.Label htmlFor="type0">{`Apartment`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                        {!!state.errors?.type && (
                            <div className="invalid-feedback font-normal">
                                {state.errors.type}
                            </div>
                        )}
                    </Col>
                </Row>
            </ListGroup.Item>

            {/*DEPOSIT NUMBER*/}
            <ListGroup.Item className="bg-light">
                <Row className="bold  align-items-center">
                    <Col xs={5}>
                        Deposit
                    </Col>
                    <Col>
                        <NumberFormat
                            value={depositDisplayValue}
                            name="deposit"
                            //onBlur={onBlur}
                            onFocus={(e) => {
                                if (e.target.value === "€ 0") {
                                    e.target.value = ''
                                }
                            }}
                            className={`text-end form-control bold ${state.errors?.deposit ? 'is-invalid' : ''}`}
                            allowNegative={false}
                            type="int"
                            prefix="€ "
                            decimalScale={0}
                            thousandSeparator={true}
                            pattern="\d*"
                            autoComplete="off"
                            onChange={handleDepositChange}
                        />
                        <div className="invalid-feedback font-normal">
                            {state.errors?.deposit}
                        </div>
                    </Col>
                </Row>
            </ListGroup.Item>
            {/*REBATE NUMBER*/}
            <ListGroup.Item
                className={`bg-light ${!!state.values?.ftb && !!state.values?.htbs && !!state.values?.htbs ? '' : 'd-none'}`}>
                <Row className="bold  align-items-center">
                    <Col xs={5}>
                        Help To Buy
                        <TinyPopOver open={false} positions={['right','bottom']} button={<FaInfoCircle size={14}/>}>For illustrative purposes, it is assumed that you qualify for the maximum rebate available.</TinyPopOver>
                    </Col>
                    <Col>
                        <NumberFormat
                            value={state.defaultValues.rebate}
                            name="rebate"
                            className={`text-end form-control-plaintext pe-2 bold ${state.errors?.rebate ? 'is-invalid' : ''}`}
                            allowNegative={false}
                            type="int"
                            readOnly={true}
                            prefix="€ "
                            decimalScale={0}
                            thousandSeparator={true}
                            pattern="\d*"
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </ListGroup.Item>
            {/*SHARED EQUITY NUMBER*/}
            <ListGroup.Item
                className={`bg-light ${!!state.values?.ftb && !!state.values?.fhs && !!state.values?.cc ? '' : 'd-none'}`}>
                <Row className="bold  align-items-center">
                    <Col xs={5}>
                        First Home
                    </Col>
                    <Col>
                        <NumberFormat
                            value={state.defaultValues.shared < 0 ? 0 : state.defaultValues.shared}
                            name="shared"
                            onBlur={onBlur}
                            className={`text-end form-control-plaintext bold ${state.errors?.shared ? 'is-invalid' : ''}`}
                            allowNegative={false}
                            type="int"
                            readOnly={true}
                            prefix="€ "
                            decimalScale={0}
                            thousandSeparator={true}
                            pattern="\d*"
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </ListGroup.Item>
            {/*LOAN AMOUNT NUMBER*/}
            <ListGroup.Item className="bg-light">
                <Row className="bold  align-items-center">
                    <Col xs={5}>
                        Loan Amount
                    </Col>
                    <Col>
                        <NumberFormat
                            value={state.defaultValues.loan}
                            name="loan"
                            className={`text-end form-control-plaintext pe-2 bold ${state.errors?.loan ? 'is-invalid' : ''}`}
                            allowNegative={false}
                            type="int"
                            readOnly={true}
                            prefix="€ "
                            decimalScale={0}
                            thousandSeparator={true}
                            pattern="\d*"
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </ListGroup.Item>

        </ListGroup>
        </Form>
    );
}

export default Inputs3;