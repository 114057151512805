import React from 'react';
import lenderUrl from "../../assets/js/bank-image-url";
import {FaCheckCircle, FaExclamationTriangle} from "react-icons/fa";
import {Col, Row} from "react-bootstrap";

function ScaleInText(props) {
    return <span className={`w-100 delay-${props.delay}`}>{props.children}</span>
}
function Rate(props) {
    const getLogo = () => {
        const url = lenderUrl(props.rate.lender)
        return <div className='result-logo'
                    style={{backgroundImage: `url(${url})`, backgroundPosition: 'center center'}}/>
    }

    let topText = props.rate.fixed === 0 ? '' : <><span className="badge bg-danger micro-badge">{`${props.rate.fixed.toString()}`}</span> {props.rate.fixed > 1 ? ' years' : ' year'}</>
    let middleText = `${props.rate.rate}%`
    let bottomText = props.rate.fixed === 0 ? 'Variable rate' : `Fixed rate`
    return (
        <>


            <div className={`d-flex flex-column h-100 `}>

                <div className="d-flex justify-content-center">
                    {getLogo()}
                </div>

                <ul className="my-3 list-group list-unstyled d-flex flex-column gap-2 bold">
                    {
                        props.rate?.promotions && props.rate.promotions.map(item => {
                            return (
                                <li key={item} className="d-flex align-items-start gap-2 lh-1">
                                    <FaCheckCircle className="flex-shrink-0 text-success"/> {item}
                                </li>
                            )
                        })
                    }
                    <li className="d-flex align-items-center gap-2 lh-1">
                        <FaCheckCircle
                            className="text-success flex-shrink-0"/>Max {props.rate.ltv}%
                        LTV satisfied
                    </li>
                    {
                        props.rate?.conditions && props.rate.conditions.map(item => {
                            return (
                                <li key={item} className="d-flex align-items-center gap-2 lh-1">
                                    <FaExclamationTriangle
                                        className="flex-shrink-0 text-warning"/> {item}
                                </li>
                            )
                        })
                    }
                </ul>


                <Row className="mt-auto text-center text-nowrap">
                    <Col>

                        <div className=" d-flex flex-column justify-content-end align-items-center h-100">
                            <small className="text-muted">Estimated</small>
                            <ScaleInText delay={props.delay} key={'monthly'+props.rate.id}>
                            <span className={`w-100 btn bg-${props.variant} text-white mb-0 bold  cursor-default`}>
                                €{props.rate.monthly.toLocaleString('en-US')} </span>
                            </ScaleInText>
                            <small className="text-muted bold">Per month</small>
                        </div>

                    </Col>
                    <Col>
                        <div className="  d-flex flex-column justify-content-end align-items-center h-100">
                            <small className="text-muted">{topText}</small>
                            <ScaleInText delay={props.delay+1} key={'rate'+props.rate.id}>
                            <span
                                className={`w-100 btn bold btn-outline-${props.variant} text-${props.variant} mb-0 bg-white cursor-default`}>{middleText}</span>
                            </ScaleInText>
                            <small className="text-muted bold">{bottomText}</small>
                        </div>
                    </Col>
                </Row>


            </div>
        </>
    )

}
export default Rate;