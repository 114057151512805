import React from 'react';
import RatesProvider from "../RatesProvider";
import Iframe from "./Iframe";

function Wrapper(props) {
    return (
        <RatesProvider>
          <Iframe/>
        </RatesProvider>
    );
}

export default Wrapper;