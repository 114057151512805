import React from 'react';

function Blurb(props) {

    return (
        <div>
            <h3><strong>The Bawnogues</strong> in tranquil Kilcock</h3>
            <p>Experience contemporary living within this wonderful new neighbourhood. The Bawnogues is a new
                development being brought to life by Rycroft Homes. Bawnogue is an old Irish word referencing a patch of
                level grass often used for dancing, and there is more than enough space for just that in your new home.
                With plenty of green spaces to enjoy in the development alongside lush green countryside for miles
                around the locality, there are some stunning natural resources to explore and enjoy here. Sitting on the
                Kildare, Meath border, the River Rye, and the Royal Canal flow through Kilcock. If you take a stroll
                around the area you’ll find them, and on your travels you’ll also pass the fabulous St. Coca’s Church
                and much more. This lovely town is filled with history, charm, and a real sense of welcome. </p>

        </div>
    );
}

export default Blurb;
