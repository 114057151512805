import React from 'react';
import {Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {
    FaBath,
    FaBed,
    FaCertificate, FaCoffee,
    FaDoorOpen,
    FaHome,
    FaPaintRoller,
    FaPowerOff, FaSun,
} from "react-icons/fa";

function Benefits(props) {
    const size = 30
    const items =
        [
            [
                {
                    title: "SUPERIOR LOW ENERGY DESIGN",
                    icon: <FaSun size={size}/>,
                    specs: [
                        "A2 - A3 BER Rating - energy efficient homes",
                        "Low energy, low carbon houses",
                        "Very high levels of insulation incorporated in floors, walls and roofs",
                        "All houses are constructed to provide a high level of air tightness to a standard that exceeds building regulations",
                        "High performance, low U-value windows and external doors",
                        "Low maintenance Aluclad & UPVC windows",
                        "Ultra-Tech, triple locking front doors",
                        "Natural and mechanical ventilation system throughout the houses",
                    ]

                },
                {
                    title: "BUILDING GUARANTEE",
                    icon: <FaCertificate size={size}/>,
                    specs: [
                        "These quality homes are covered by the HomeBond 10 Year Structural Guarantee Scheme",
                    ]

                },
                {
                    title: "KITCHENS",
                    icon: <FaCoffee size={size}/>,
                    specs: [
                        "Superior quality kitchens",
                        "Fitted storage units to utility room",
                        "Down lighters and velux window to kitchen area"
                    ]

                }
            ],
            [
                {
                    title: "EXTERNAL FINISHES & STRUCTURE",
                    icon: <FaHome size={size}/>,
                    specs: [
                        "The houses are constructed using elevation of brick and low maintenance rendered finish",
                        "The elevations incorporate a mix of low maintenance brick and offering an attractive high quality low maintenance finish",
                        "Low maintenance UPVC fascia, soffits and gutters",
                        "Landscaping with paved driveways and concrete rear patios",
                        "Rear gardens are levelled, top soiled, raked and seeded",
                        "Post and panel side boundary walls and block back wall",
                        "External wiring for car charging point",
                        "Side gate fitted as standard where applicable"
                    ]

                },
                {
                    title: "INTERNAL FINISHES",
                    icon: <FaPaintRoller size={size}/>,
                    specs: [
                        "Internal walls plastered and painted one colour throughout",
                        "Painted skirting and architraves",
                        "Staircase and balustrade painted as per showhouse",
                        "Standard pendant light fittings and attractive power points",
                    ]

                },
                {
                    title: "BEDROOMS",
                    icon: <FaBed size={size}/>,
                    specs:
                        [
                            "Luxury fitted wardrobes with a combination of hanging and shelved space as per showhouse"
                        ]

                }

            ],
            [
                {
                    title: "WINDOWS, DOORS & IRONMONGERY",
                    icon: <FaDoorOpen size={size}/>,
                    specs: [
                        "Composite front door with multi point locking system",
                        "High specification joinery and internal doors and ironmongery fit out",
                        "Premium Alu-clad & UPVC windows for a long lasting durable low maintenance composite product",
                        "Elegant painted internal doors",
                        "Attractive modern styled door ironmongery",
                    ]
                },
                {
                    title: "BATHROOMS",
                    icon: <FaBath size={size}/>,
                    specs: [
                        "Stylish bathrooms and ensuites",
                        "WC with attractive range of high quality sanitary ware and fittings",
                        "Bathroom and ensuite wet areas tiled as per showhouse",
                        "Large shower trays to ensuites",
                    ]
                },
                {
                    title: "ELECTRICAL",
                    icon: <FaPowerOff size={size}/>,
                    specs: [
                        "Wired for TV / Broadband",
                        "CAT 6 cable wiring for data telephone points for high speed",
                        "Smoke / heat and carbon monoxide detectors fitted as standard"
                    ]

                }
            ]
        ]

    return (
        <div className="pb-5">
            <Container className="px-xs-0-only">
                <h3 className="mt-5 mb-3 p-3 p-md-5 bg-info text-white">The Bawnogues' Specifications</h3>
            </Container>
            <Container>
                <Row>
                    {items.map((col, i) => {

                        return (<Col key={`col-${i}`} xs={12} lg={4}>
                            {items[i].map(item => {
                                return (
                                    <div className="" key={item.title+"div"}>
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1 my-3">
                                            <h5 className="mt-3 mb-0">{item.title}</h5>
                                            <span className="flex-shrink-0 text-info">
                                                {item.icon}
                                            </span>
                                        </div>
                                        <ListGroup>
                                            {item.specs.map(spec => {
                                                return <ListGroupItem className="bg-light" key={spec}>
                                                    {spec}
                                                </ListGroupItem>

                                            })}
                                        </ListGroup>
                                    </div>

                                )
                            })
                            }
                        </Col>)

                    })}
                </Row>
            </Container>
        </div>
    );
}

export default Benefits;